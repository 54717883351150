import React from 'react';

const PictureGrid = (props) => {
  return (
    <div className="row d-flex row-center">
      {props.Images.map(
        (image, index) => {
          return (
            <div key={index} className="col-md-3 col-lg-3">
              <div className="project-item">
                <img src={image.path} alt={image.alt} />
              </div>
            </div>
          )
        }
      )}
    </div>
  )
};

export default PictureGrid;
