import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { Link } from "gatsby";

import CalltoAction from "../components/CalltoAction";
import PictureGrid from "../components/PictureGrid";
import PageIntro from "../components/PageIntro";
import AlternatingRowsContent from "../components/AlternatingRows";

import $ from "jquery/dist/jquery";

const ImageArray = [
  { path: "../../window-cl-pr1.png", alt: "window-cl-pr1" },
  { path: "../../window-cl-pr2.png", alt: "window-cl-pr2" },
  { path: "../../window-cl-pr3.png", alt: "window-cl-pr3" },
  { path: "../../window-cl-pr4.png", alt: "window-cl-pr4" },
];

class Services extends React.Component {
  componentDidMount() {
    var $menu = $(".main-menu ul").clone();
    $("#mobile-menu").html($menu);
    $("#mobile-menu ul li:last-child").remove();

    $(".mobile-menu-icon").on("click", function() {
      $(".mobile-menu-wrap").toggleClass("mobile-menu-show");
    });

    $(".close-btn").on("click", function() {
      $(".mobile-menu-wrap").removeClass("mobile-menu-show");
    });

    if (window.location.hash) {
      let url = window.location.href.replace(/\/$/, "");
      const hash = url.split("#");
      $('#myTab a[href="#' + hash[1] + '"]').tab("show");
      url = window.location.href.replace(/\/#/, "#");
      window.history.replaceState(null, null, url);
      setTimeout(() => {
        $(window).scrollTop(0);
      }, 400);
    }
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="google-site-verification"
            content="YAjmKXP8olHAA_JpbqTrbgHbFFvG4h8yvrpmb1lzL1w"
          />
          <title>
            Services by GG Cleaning Ireland | Window Cleaning, Carpet Cleaning,
            Contract Cleaning in Castlebar, Ireland
          </title>
          <description>
            We offer a full range of window cleaning options for our residential
            clients based in the Castlebar area & commercial clients Nationwide.
          </description>
        </Helmet>

        <div>
          {/* Start header section */}
          <section className="header-section">
            <div className="d-flex align-items-center">
              <div className="col-lg-12 header-left order-lg-1 order-md-2 order-2 pad0">
                <div className="home-banner serviceBanner half-height-banner underlay">
                  <div className="home-title">
                    <h2>GG Cleaning Services</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End header section */}
          <section className="service-wrapper">
            <div>
              <ul className="nav nav-tabs" id="myTab">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#window-cleaning"
                  >
                    Window Cleaning
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#carpet-cleaning"
                  >
                    Carpet, Rug and Upholstery Cleaning
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#contract-cleaning"
                  >
                    Contract and Office Cleaning
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="window-cleaning">
                  <PageIntro
                    // icon="../../windowiconred.png"
                    heading="We offer a full range of window cleaning options for our residential clients based in the Castlebar area & commercial clients Nationwide."
                  />

                  {/* Start ImageContentRow section */}
                  <AlternatingRowsContent
                    imgAlignment="right"
                    fgImg="../../window-cleaning/wash-image-left.png"
                    fgImgAlt="foreground-image"
                    bgImg="../../window-cleaning/wash-image-right.png"
                    bgImgAlt="background-image"
                    heading="Reach and Wash"
                    paragraph="At GG Cleaning we use a Reach & Wash System which allows windows to be cleaned from the safety of the ground using long reaching, telescopic poles. A soft bristled brush is used to scrub the dirt off the glass, while jets of pure water rinse the window.  The pure water, as it has no impurities, dries to a spot-free finish leaving no marks behind.  Reach & Wash can clean to a height of 45ft, well beyond the reach of ladders, and is much safer and more efficient than other methods of working at height."
                  />
                  <AlternatingRowsContent
                    imgAlignment="left"
                    fgImg="../../window-cleaning/traditional-image-right.png"
                    fgImgAlt="foreground-image"
                    bgImg="../../window-cleaning/traditional-image-left.png"
                    bgImgAlt="background-image"
                    heading="Traditional Method"
                    paragraph="We also use the traditional window cleaning method where the reach & wash system is unsuitable. This method involves the use of the traditional applicator and squeegee.  All windows are cleaned using the applicator with soap solution and the windows are squeezed dry of all excess water with the squeegee."
                  />
                  {/* End ImageContentRow section */}
                </div>

                <div className="tab-pane fade" id="carpet-cleaning">
                  <PageIntro
                    // icon="../../windowiconred.png"
                    heading="GG Cleaning Services offer professional Carpet, Rug & Upholstery Cleaning services to both residential & commercial clients based in County Mayo."
                  />

                  {/* Start ImageContentRow section */}
                  <AlternatingRowsContent
                    imgAlignment="right"
                    fgImg="../../window-cleaning/carpet-after.png"
                    fgImgAlt="foreground-image"
                    bgImg="../../window-cleaning/carpet-before.png"
                    bgImgAlt="background-image"
                    heading="Carpet & Rug Cleaning"
                    paragraph="Having your carpets and rugs cleaned professionally will prolong the overall life span of your carpet.  We use a hot water extraction system after applying a pre-spray which is gently agitated into the carpet fibres.  This process allows us to get down to the base of your carpet lifting and extracting even the most stubborn dirt and grime as well as allergens and mites, leaving your carpets not only looking, but smelling fresh as well."
                  />
                  <AlternatingRowsContent
                    imgAlignment="left"
                    fgImg="../../lounge-2.jpg"
                    fgImgAlt="foreground-image"
                    bgImg="../../lounge-1.jpg"
                    bgImgAlt="background-image"
                    heading="Upholstery Cleaning"
                    paragraph="If your lounge suite is in need of some TLC we also clean upholstery using our twin motor vacuum system, which results in a faster drying time. All our staff are fully trained and will treat your carpets and upholstery with the respect they deserve."
                  />
                  {/* End ImageContentRow section */}
                </div>

                <div className="tab-pane fade" id="contract-cleaning">
                  <PageIntro
                    // icon="../../windowiconred.png"
                    heading="By choosing GG Cleaning you can be assured that your premises will be impeccably maintained and will reflect the professional image you wish to project to your clients and employees."
                  />

                  {/* Start ImageContentRow section */}
                  <AlternatingRowsContent
                    imgAlignment="right"
                    fgImg="../../window-cleaning/office-after.jpg"
                    fgImgAlt="foreground-image"
                    bgImg="../../window-cleaning/office-before.jpg"
                    bgImgAlt="background-image"
                    heading="Our Contract Cleaning"
                    paragraph="Our Office Cleaning Service in Castlebar and surrounding areas can include: Carpet & upholstery cleaning, Hoovering floors, Mopping floors, Cleaning of restrooms & canteens, General dusting/cleaning as required."
                  />
                  {/* End ImageContentRow section */}
                </div>
              </div>
            </div>
          </section>

          {/* Start ImageGallery section */}
          <div>
            <section className="section-gap project-area">
              <div className="projectTitle service-page-content">
                <div className="container">
                  <h4>Our Window Cleaning Projects</h4>
                  <a href="/projects" className="view-all-btn">
                    View All <img src="../../arrow.png" alt="arrow" />
                  </a>
                </div>
              </div>
              <div className="project-list">
                <PictureGrid Images={ImageArray} />
              </div>
            </section>
          </div>
          {/* End ImageGallery section */}

          {/* Start request section */}
          <CalltoAction
            bgimage="../../footer-baner.png"
            btnbgcolor="#D91F3D"
            btncolor="#fff"
            heading={"Let us help your business shine"}
            action="/contact"
            btnval="Request a quote"
          />
          {/* End request section */}
        </div>
      </Layout>
    );
  }
}

export default Services;
